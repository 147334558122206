import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/Users/tlarson/dev/home/familyhistories/node_modules/gatsby-theme-docz/src/base/Layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h3 {...{
      "id": "scenarios"
    }}>{`Scenarios`}</h3>
    <p>{`Here are some use cases I envision:`}</p>
    <ul>
      <li>Share files with selected people. (I have some interviews and journals in raw text that I'm converting to HTML for easier searching; I would also like to annotate them with semantic markup or add other XML documentation that help identify individuals/time-frames/localities for more targeted searching. Then I'd like others in my family to be able to get those updates, either to improve/replace their copies or discard them, sending me notes of encouragement or rebuke as they see my work. My sister has >2G of files that I want to copy and annotate/document, and my brother has done genealogy work that I want.)</li>
      <li>Offer the bundle of Tolman histories for download, and allow people to subscribe so they'll automatically get the latest when we release it.  They'll be able to review differences when they get those updates.
        <ul>
          <li>Search by: keywords, dates, individuals</li>
          <li>Search only for things that apply to my ancestry (and avoid people who aren't in my direct line).</li>
        </ul>
      </li>
      <li>Share groups of files with groups of people.</li>
      <li>Share between individuals without the need of any central server.</li>
      <li>Notify me about changes to files, and show me just those changes.</li>
      <li>Notify me about new files someone has added, and let me choose whether to add it to mine.</li>
      <li>Ideally: do the same for bits of information, such as ancestor data found in a history.</li>
      <li>Install easily on Windows, Mac, Linux.</li>
      <li>Secure transmission.</li>
      <li>Authenticated & authorized sharing.</li>
    </ul>
    <p>{`For more motivation and direction of this project, see `}<a parentName="p" {...{
        "href": "http://effectivesociety.blogspot.com/search/label/p2p-docs"
      }}>{`these blog entries`}</a>{`.`}</p>
    <p>{`For progress updates, you'll want to `}<a parentName="p" {...{
        "href": "http://feeds.feedburner.com/p2p-docs"
      }}>{`subscribe`}</a>{`.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      